<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import echarts from 'echarts'

require('echarts/theme/macarons')
import {debounce} from '@/utils'

export default {
  name: "UserScale",
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null,
      queryForm: {
        startTime: '',
        endTime: ''
      },
    }
  },
  mounted() {
    this.chart = echarts.init(this.$el, 'macarons')
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize()
      }
    }, 100)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    refreshCart(queryForm) {
      this.queryForm = queryForm
      this.initChart()
    },
    initChart() {
      this.$http({
        url: this.$http.adornUrl("/admin/report/userScale"),
        method: "POST",
        data: this.queryForm
      }).then(({data}) => {
        if (data && data.code === 0) {

          const newUserReport = data.result.newUserReport
          const dauReport = data.result.dauReport
          const day = []
          const newUserReportValue = []
          const dauReportValue = []
          newUserReport.forEach(function (item) {
            day.push(item.statDate)
            newUserReportValue.push(item.value)
          })
          dauReport.forEach(function (item) {
            dauReportValue.push(item.value)
          })
          this.chart.setOption({
            title: {
              text: '用户规模'
            },
            tooltip: {
              trigger: 'axis'
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            legend: {
              data: ['新增用户数', 'DAU']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: day,
              boundaryGap: false
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [{
              name: '新增用户数',
              type: 'line',
              data: newUserReportValue
            },
              {
                name: 'DAU',
                type: 'line',
                data: dauReportValue
              }]
          })
        } else {
          this.$message.error(data.msg)
        }
      }).catch((err)=>{
        console.error(err)
        this.$message.error(err);
      })
    }
  }
}
</script>
